




import { Component } from 'vue-property-decorator';
import ChartBase from '../ChartBase';
import BarChartData from './modelsTable';

@Component
export default class ChartTable extends ChartBase {
  declare chartData: BarChartData;

  rows: any | null = null;

  onInitOrUpdate(): void {
    this.rows = this.queryResult;
  }

  get tableFields(): {text: string, value: string}[] | null {
    if (!this.rows) return null;
    return Object.keys(this.rows[0]).map((x) => ({ text: x, value: x }));
  }
}
